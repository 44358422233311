@import "_variables";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap&subset=latin-ext');

:root {
    font-size: 15px;
}

* {
    font-size: 14px;
    font-size: 1rem;
    letter-spacing: 1.4px;
    // cursor: none !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

html,
body {
    margin: 0;
    position: relative;
    overflow-x: hidden;
    height: 100%;
    max-height: 100%;
}

body::-webkit-scrollbar-track {
    background-color: $white;
}

body::-webkit-scrollbar {
    width: 4px;
    background: $primary-color-1;
}

body::-webkit-scrollbar-thumb {
    background: $primary-color-1;
}

::selection {
    background: $primary-color-1;
    color: $white;
}

::-moz-selection {
    background: $primary-color-1;
    color: $white;
}

@mixin no__select {
    -webkit-touch-callout: none;
    /* iPhone OS, Safari */
    -webkit-user-select: none;
    /* Chrome, Safari 3 */
    -khtml-user-select: none;
    /* Safari 2 */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+ */
    user-select: none;
    /* Possível implementação no futuro */
    /* cursor: default; */
}

.blend {
    font-size: 10vw;
    margin: 0;
    mix-blend-mode: difference;
    position: fixed;
    top: 100px;
    color: white;
}

.first-frame__image {
    position: fixed;
    top: 5%;
    left: 2%;
    width: 70px;
    cursor: pointer;
    z-index: 999;
}

menu {
    position: fixed;
    z-index: 2;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    background: white;
    padding: 0;
    border-radius: 0 3px 0 0;
    @include no__select;

    a {
        text-decoration: none;
        font-weight: bold;
        font-weight: 700;
        position: relative;
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        color: $black;

        &::before {
            content: '';
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 12px;
            height: 1px;
            width: 80%;
            margin: 0 auto;
            transition: all 0.2s ease;
            transform: scale(0);
            border-radius: 40px;
            z-index: -1;
            vertical-align: middle;
        }

        &.selected,
        &:hover {
            &::before {
                background: $black;
                transform: scale(1);
            }
        }
    }
}

.page__container {
    background-color: linear-gradient(0.25turn, $white, $primary-color-2, $white);
    min-height: 100vh;
    width: 100vw;
}

#home {
    display: flex;
    align-items: center;
    justify-content: center;
    @include no__select;

    .lang {
        position: absolute;
        top: 2%;
        right: 2%;

        span {
            &.selected {
                border-bottom: 1px solid $black;
            }
        }
    }

    h1 {
        font-weight: 700;
        font-size: 3rem;
        margin: 0;
        line-height: 48px;
    }

    .name {
        outline: none;
        text-decoration: none;
        position: relative;
        font-weight: 700;
        font-size: 3rem;
        margin: 0;
        line-height: 48px;
        color: $black;
        display: inline-block;

        &.name__effect {
            font-weight: 700;
            overflow: hidden;
            color: $black;

            &:hover {
                color: #b2b0a9;

                &::after {
                    transform: translate3d(100%, 0, 0);
                }

                &::before {
                    width: 100%;
                }
            }

            &::after {
                content: '';
                position: absolute;
                height: 6px;
                width: 100%;
                top: 65%;
                margin-top: -8px;
                right: 0;
                background: #F9F9F9;
                transform: translate3d(-100%, 0, 0);
                transition: transform 0.4s;
                transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

            }

            &::before {
                content: attr(data-letters);
                position: absolute;
                z-index: 2;
                overflow: hidden;
                color: #424242;
                white-space: nowrap;
                width: 0%;
                transition: width 0.4s 0.3s;
            }
        }
    }

    h3 {
        font-weight: 300;
        font-size: 1.8rem;
        position: relative;
        margin-top: 1rem;

        &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 3px;
            width: 30%;
            height: 3px;
            background: rgb(0, 0, 0);
        }
    }
}

#work {
    @include no__select;
    background: linear-gradient(0.25turn, $black, $primary-color-3, $black);
    padding: 2rem 0;

    h1 {
        margin: 0;
        font-size: 12rem;
        padding-right: 10%;
        letter-spacing: -.05em;
        color: $white;
        line-height: 10rem;
        text-align: right;
        font-weight: 400;
    }

    .project {
        width: 80%;
        margin: 0 auto;
        color: white;
        display: flex;
        flex-direction: column;

        article {
            display: grid;
            grid-template-columns: 4% 50vw 1fr;
            margin: 4rem 0;

            .number {
                display: flex;
                align-self: flex-end;
                padding-bottom: 5rem;
                font-weight: 100;
            }

            .grid__img {
                position: relative;

                video {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 100%;
                }

                .tilt {
                    overflow: hidden;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;

                    .tilt__back,
                    .tilt__front {
                        width: 100%;
                        height: 100%;
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;
                        min-height: 500px;
                        transition: .1s all;
                    }

                    .tilt__front {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                img {
                    max-width: 100%;
                }
            }

            .infos {
                padding: 0 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h3 {
                    font-size: 1.4rem;
                    margin: 0;
                }

                hr {
                    width: 15%;
                    ;
                    height: 1px;
                    border: 0;
                    border-top: 1px solid $white;
                    margin: 3rem 0;
                    padding: 0;
                }

                span {
                    font-weight: 100;
                    font-size: .90rem;
                    line-height: 1rem;
                    letter-spacing: .5px;
                }

                .play {
                    margin-top: 2rem;
                    width: 80px;
                    height: 80px;
                    z-index: 1;
                    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOmNjPSJodHRwOi8vY3JlYXRpdmVjb21tb25zLm9yZy9ucyMiIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzb2RpcG9kaT0iaHR0cDovL3NvZGlwb2RpLnNvdXJjZWZvcmdlLm5ldC9EVEQvc29kaXBvZGktMC5kdGQiIHhtbG5zOmlua3NjYXBlPSJodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy9uYW1lc3BhY2VzL2lua3NjYXBlIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEyNSI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCwtOTUyLjM2MjE4KSI+PHBhdGggc3R5bGU9InRleHQtaW5kZW50OjA7dGV4dC10cmFuc2Zvcm06bm9uZTtkaXJlY3Rpb246bHRyO2Jsb2NrLXByb2dyZXNzaW9uOnRiO2Jhc2VsaW5lLXNoaWZ0OmJhc2VsaW5lO2NvbG9yOiMwMDAwMDA7ZW5hYmxlLWJhY2tncm91bmQ6YWNjdW11bGF0ZTsiIGQ9Im0gNDUuOTYxOTQsOTY3LjM2MjE5IGMgLTE5LjMwNjE5LDAgLTM1LDE1LjY5Mzg0IC0zNSwzNS4wMDAwMSAwLDE5LjMwNjIgMTUuNjkzODEsMzUgMzUsMzUgMTkuMzA2MiwwIDM1LC0xNS42OTM4IDM1LC0zNSAwLC0xOS4zMDYxNyAtMTUuNjkzOCwtMzUuMDAwMDEgLTM1LC0zNS4wMDAwMSB6IG0gMCw0IGMgMTcuMTQ0NCwwIDMxLDEzLjg1NTY0IDMxLDMxLjAwMDAxIDAsMTcuMTQ0NCAtMTMuODU1NTksMzEgLTMxLDMxIC0xNy4xNDQzOSwwIC0zMSwtMTMuODU1NiAtMzEsLTMxIDAsLTE3LjE0NDM3IDEzLjg1NTYxLC0zMS4wMDAwMSAzMSwtMzEuMDAwMDEgeiBtIC03LjY1NjI1LDE2IGMgLTEuNzY3ODMsMC4wNDExIC0zLjI5ODU2LDEuNDk4NzEgLTMuMzQzNzUsMy41OTM3NSBsIDAsMjIuNzgxMjYgYyAwLjA2MDQsMi43OTMzIDIuNzgwMzksNC40ODYgNS4xNTYyNSwzLjE1NjIgbCAxOS4wOTM3NSwtMTEuNDM3NSBjIDEuMDQwNTcsLTAuNjIzOSAxLjc1LC0xLjc1NDIgMS43NSwtMy4wOTM3IDAsLTEuMzM5NSAtMC43MDk0MywtMi41MDExNyAtMS43NSwtMy4xMjUwMSBMIDQwLjExODE5LDk4Ny44MzA5NCBjIC0wLjU5Mzk3LC0wLjMzMjQ1IC0xLjIyMzIyLC0wLjQ4MjQ0IC0xLjgxMjUsLTAuNDY4NzUgeiIgZmlsbD0id2hpdGUiIGZpbGwtb3BhY2l0eT0iMSIgc3Ryb2tlPSJub25lIiBtYXJrZXI9Im5vbmUiIHZpc2liaWxpdHk9InZpc2libGUiIGRpc3BsYXk9ImlubGluZSIgb3ZlcmZsb3c9InZpc2libGUiLz48L2c+PC9zdmc+);
                    background-repeat: no-repeat;
                    transition: .35s all;

                    &:hover {
                        transform: scale(1.09);
                    }
                }
            }
        }
    }
}

#contact {
    padding: 6rem 0 2rem 0;
    @include no__select;

    h1 {
        font-size: 5rem;
        line-height: 5rem;
        margin: 0;
    }

    .title__big {
        padding-left: 10%;
        font-size: 17rem;
        background: black;
        color: white;
        line-height: 14rem;
        text-transform: lowercase;
    }

    .form {
        margin-top: 5rem;
        width: 80%;

        .field {
            position: relative;
            margin-bottom: 48px;

            .field__input,
            .field__textarea {
                width: 100%;
                background-color: transparent;
                border: 0;
                color: currentColor;
                border-bottom: 1px solid #060807;
                padding: 24px 0;
                outline: none;
                border-radius: 0;
                font-size: 1.3rem;
                cursor: auto !important;

                &::placeholder {
                    color: #000;
                }
            }

            .field__help-text {
                position: absolute;
                left: 0;
                bottom: -32px;
                font-size: 1.6rem;

                &.error {
                    color: #ca3939;
                }
            }
        }

        a.btn {
            border: 0;
            font-family: space-grotesk-regular, sans-serif;
            display: inline-block;
            padding: 1.9rem 3.4rem;
            border: 1px solid $black;
            color: #424242;
            outline: 0 solid #060807;
            transition: .2s all;
            font-weight: 300;
            font-size: 1.3rem;

            &[disabled="disabled"] {
                opacity: .2;
            }

            &:hover {
                color: $black;
            }
        }

        h3 {
            transition: .2s all;

            &:first-child {
                opacity: 0;
                color: $primary-color-1;
                font-size: 1.2rem;
            }

            &:last-child {
                display: none;
                color: $primary-color;
                font-size: 1.2rem;
            }
        }
    }
}

.animation__loading {
    display: none;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    border: 2px solid $black;
    border-top-color: white;
    border-bottom-color: white;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}

.clg-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8.75rem;
    grid-row-gap: .75rem;
}

.container-80 {
    width: 80%;
    margin: 0 auto;
}

//cursor
.cursor {
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
}

.cursor--small {
    width: 5px;
    height: 5px;
    left: -2.5px;
    top: -2.5px;
    border-radius: 50%;
    z-index: 11000;
    background: $white;
    // background: black;
}

.cursor--canvas {
    width: 100vw;
    height: 100vh;
    z-index: 12000;
}

.translationFadeOut {
    animation: fadeOutWithSlide .5s linear;
}

@keyframes fadeOutWithSlide {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }

    50% {
        transform: translateX(10%);
    }

    100% {
        transform: translateX(20%);
        transform: scale(.7);
        opacity: 0;
    }
}

.translationFadeIn {
    animation: fadeIn .5s linear;
}

@keyframes fadeIn {
    0% {
        transform: translateX(-20%);
        transform: scale(.7);
        opacity: 0;
    }

    50% {
        transform: translateX(-10%);
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}


@import "_responsive";