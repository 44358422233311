// 1. Colors
// ==========================================================================1


$primary-color-1: #ff0000;
$primary-color-2: rgb(222, 221, 221);
$primary-color-3: rgb(60, 57, 57);


//for all layouts
$white: #FFFFFF; //
$black: #000000; //


$primary-color: #5aa5dc; //laranja
$primary-color-light: lighten($primary-color, 55%);
$primary-color-dark: darken($primary-color, 15%);
