@media handheld,
only screen and (max-width: 1250px) {
    body {
        padding-bottom: 150px;
    }

    menu {
        width: 100%;
        position: fixed;
        box-shadow: 0px 5px 10px 0px black;
    }

    #work .project {
        width: 90%;
    }

    .container-80 {
        width: 90%;
    }

    #contact .title__big {
        padding-left: 5%;
    }
}

@media handheld,
only screen and (max-width: 992px) {
    .cxs-1 {
        grid-template-columns: repeat(1, 1fr);
    }

    #contact {
        padding: 2rem 0 2rem 0;

        h1 {
            font-size: 3rem;
            line-height: 3rem;
        }

        .title__big {
            font-size: 10rem;
            line-height: 8rem;
        }

        .form {
            width: 100%;
        }
    }
}

@media handheld,
only screen and (max-width: 950px) {
    #work {
        h1 {
            font-size: 9rem;
            line-height: 7rem;
        }

        .project {
            width: 95%;

            article {
                display: grid;
                grid-template-columns: 1fr;
                margin: 2rem 0;
                text-align: center;

                .number {
                    display: block;
                    text-align: center;
                    padding-bottom: 1rem;
                }

                .grid__img video {
                    left: 0;
                }

                .infos {
                    align-items: center;

                    h3 {
                        padding-top: 2rem;
                    }

                    hr {
                        width: 100%;
                    }
                }
            }
        }
    }

    .container-80 {
        width: 95%;
    }

    #contact .title__big {
        padding-left: 2%;
    }
}

@media handheld,
only screen and (max-width: 600px) {
    #home {

        a,
        h1 {
            font-size: 2rem !important;
            line-height: 25px;
        }

        h3 {
            font-size: 1.3rem;
        }
    }

    #work h1 {
        font-size: 6rem;
        line-height: 5rem;
    }

    #contact {
        h1 {
            font-size: 2.2rem;
            line-height: 2rem;
        }

        .title__big {
            font-size: 6rem;
            line-height: 5rem;
        }

        .form {
            margin-top: 0rem;
        }
    }
}

@media handheld,
only screen and (max-width: 400px) {
    #home {

        a,
        h1 {
            font-size: 1.6rem !important;
            line-height: 20px;
        }

        h3 {
            font-size: 1.25rem;
        }
    }

    #contact .title__big {
        font-size: 4rem;
        line-height: 4rem;
    }
}